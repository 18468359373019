import React, { useEffect, useState } from "react";
import "./App.css";
import { RetellClientSdk } from "retell-client-js-sdk";
//@ts-ignore
// import { MokAudioClient } from 'mok-call-sdk'
import { MdCallEnd, MdCall } from "react-icons/md";
//@ts-ignore
// const sdk = new MokAudioClient({customEndpoint: 'wss://calls-us.mok.one'});

// const queryParams = (url = null) => {
//   const paramsObj: any = new URLSearchParams(
//     url ? url : window.location.search
//   );
//   let newObj: any = {};
//   for (const [key, value] of paramsObj) {
//     newObj[key] = value;
//   }
//   return newObj;
// };

// interface RegisterCallResponse {
//   callId?: string;
//   sampleRate?: number;
//   enableUpdate: boolean;
// }
const sdk = new RetellClientSdk();
const App = () => {
  const [isCalling, setIsCalling] = useState<boolean>(false);
  // const { template_id, call_id } = queryParams();
  // Initialize the SDK
  useEffect(() => {
    // Setup event listeners
    sdk.on("onConversationStarted", () => {
      console.log("Conversation started");
    });

    sdk.on("onConversationEnded", (reason: any) => {
      console.log("Conversation ended");
      setIsCalling(false); // Update button to "Start" when conversation ends
    });

    sdk.on("onError", (error: any) => {
      console.error("An error occurred:", error);
      setIsCalling(false); // Update button to "Start" in case of error
    });
  }, []);

  const stopConversation = async () => {
    if (isCalling) {
      sdk.stopConversation();
      setIsCalling(false);
    }
  };

  const toggleConversation = async () => {
    if (isCalling) {
      sdk.stopConversation();
    } else {
      const registerCallResponse = await registerCall();
      // console.log(data);
      // const registerCallResponse: RegisterCallResponse = {
      //   callId: "123456",
      //   sampleRate: 24000,
      //   enableUpdate: true,
      // };
      if (registerCallResponse.callId) {
        sdk
          .startConversation({
            callId: registerCallResponse.callId,
            sampleRate: registerCallResponse.sampleRate,
            // enableUpdate: true,
          })
          .catch(console.error);
        setIsCalling(true); // Update button to "Stop" when conversation starts
      }
    }
  };

  async function registerCall(): Promise<any> {
    try {
      let BASE_URL = "https://live.mok.one/";

      // if (env === "dev") {
      //   BASE_URL = "https://dev.mok.one/";
      // } else if (env === "local") {
      //   BASE_URL = "http://localhost:8080/";
      // }

      const response = await fetch(
        BASE_URL + "api/register-call-on-your-server",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "r_IG5F6qphtpRE8uNZxtaby",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
      return false; // Return an error
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="main">
          <div className="img-container">
            <img src="./Logo.png" alt="logo" />
          </div>
          <h1 className="heading">Unomok</h1>
          <p>Incoming call</p>
        </div>
        {/* buttons start */}
        <div className="footer">
          {!isCalling && (
            <div className="btn-container">
              <div className="btn-wrapper">
                <button className="btn btn-accept" onClick={toggleConversation}>
                  <MdCall size={25} color="#fff" />
                </button>
                <p>{isCalling ? "Stop" : "Accept"}</p>
              </div>

              <div className="btn-wrapper" onClick={stopConversation}>
                <button className="btn btn-reject">
                  <MdCallEnd size={25} color="#fff" />
                </button>
                <p>Reject</p>
              </div>
            </div>
          )}
          {isCalling && (
            <div className="btn-container">
              <div className="btn-wrapper" onClick={stopConversation}>
                <button className="btn btn-reject">
                  <MdCallEnd size={25} color="#fff" />
                </button>
                <p>Reject</p>
              </div>
            </div>
          )}
        </div>
        {/* buttons end */}
      </header>
    </div>
  );
};

export default App;
